:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
}

@import "/fontmodules/Roboto.css";
@import "/fontmodules/RobotoCondensed.css";
@import "/fontmodules/DroidSerif.css";
@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/cssmodules/flex_grid.v1.1.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.v1.1.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
/*@import "/cssmodules/animate.css";*/



body {
    background: #FFFFFF;
    font-family: "RobotoCondensed", san-serif;
    /* font-weight's att välja mellan är 100,300,400,500,700,900 */
}


header {
    background: rgb(255, 255, 255);
    z-index: 100;
    position: fixed;
    top: 0;
    width: 100%;
}


.hemisdaochdesignav  {
    background:#999999;
    padding: 8px 20px 8px 8px;

}

.hemisdaochdesignav p, .hemisdaochdesignav a {
    font-family: "Roboto", sans-serif;
    color:#EEEEEE;
    font-size: 0.8rem;
    padding:0px;
    margin:0px;
}


/*----------------------------------- Logotype --------------------------------------*/

.logo a {
    padding: 15px 0px 15px 15px;
}/*Add width:100% för att ge egen "rad"*/


.logo img {
    height: 43px;
    max-height: 43px;
    width: auto;
}


/* ---------------------------------- Rubrikerna h1 h2 h3 h4 h5 h6 --------------------------- */

.contact h1 {
    margin-top:1vh;
    padding-top:0vh;
    margin-bottom:0vh;
    padding-bottom:0vh;
}


h1 {
    color: #666666;
    font-size: 3.5rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h2 {
    color: #666666;
    font-size: 2.0rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h3 {
    color: #666666;
    font-size: 1.8rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h4 {
    color: #666666;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h5 {
    color: #666666;
    font-size: 1.6rem;
    font-weight: 300;
    letter-spacing: 2px;
}

h6 {
    color: #666666;
    font-size: 1.4rem;
    font-weight: 300;
    letter-spacing: 2px;

}

p  {
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.2rem;
    font-weight: 300;
    line-height: 150%;
}

p strong {
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 150%;
}


/*-------------------------------------------------------------- Bildspel ------------------------------------------------------------------------*/

.slick-initialized .slick-slide{
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.slick-slider {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    top:0;
    z-index: -10; /* z-index - sätter vilken "nivå" bildspelet / "vad man vill" ska hamna på */
}

.slick-slide {
    height: 100vh;
    width:100%;
    background-size: cover;
    background-position: top center ;
}

.slideshow_titel {
    font-family: 'Roboto',arial,san-serif;
    background: rgba(51, 51, 51, 0.25);
    font-weight: 100;
    font-size: 2.8em;
    position: relative;
    text-transform: uppercase;
    color: #fff;
    letter-spacing: 3px;
    text-align: center;
    margin-top: 50vh;
    margin-bottom: 0;
    z-index: -8;
    width: 100%;
    height: 60px;
    border-style: solid;
    border-width: 2px;
    border-left: 0;
    border-right: 0;
    border-color: #fff;
}

/*-------------------------------------------------------------- Topimage / Toppbild ------------------------------------------------------------------------*/
.topimage {
    margin-top: 0px;
    position: fixed;
    width:100%; 
    height:100vh;
    top:0;
    z-index: -10; 
    background-size: cover;
    background-position: top center;
}


/*-------------------------------------------------------------- Pagecontent / Innehåll sida och startsida ------------------------------------------------------------------------*/

.pagecontent {
    margin-top:73vh;
    position: relative;
    z-index: 15;
    background: rgb(255, 255, 255);
}


.bodycol {
    margin-top:0vh;
    padding-top:0vh;
}


.pagetitle h1 {
    margin-bottom:0vh;
    padding-bottom:0vh;
}
.pagetitle {
    margin-bottom:0vh;
    padding-bottom:0vh;
}


.bodyimage {
 margin-top:2vh;
}

.pdfloop {
    color:#487d92;
    line-height: 150%;
    font-family: "RobotoCondensed", sans-serif;
    font-size: 1.2em;
    font-weight: 300;
    line-height: 150%;
    
}

.pdfloop a {
    color:#666666;
}


.margin>* {
margin-top:0px;
}


.kontaktboxinnehall {
    margin-top:0vh;
    padding-top:0vh;
}


.kontaktlankboxbild {
    min-height: 250px;
    max-height: 250px;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.kontaktlankboxbild{
    display:block;
}




/*---------- Gallery galleri galleries --------------*/

.gallerybackground {
background: rgb(255,255,255);
}

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

.imagelist_link {
    min-height: 500px;
    width: 100%;
    position: relative;
    background-size: cover;
    background-position: center center;
}

a.imagelist_link{
    display:block;
}


/*----------------------------------- Länkarna --------------------------------------*/

a {
    text-decoration: none;
}

a:active, a:hover {
    text-decoration: underline;
}

/*----------------------------------- Meny / Menu --------------------------------------*/

nav li{
    background: none;
}

nav {
    font-size:0.9rem;
    color:rgb(156, 156, 156);
    float:right;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: uppercase;
    line-height: 1.1em;
}

.menu li a,
.menu .dropdown ul
{
    color:rgb(156, 156, 156);
    text-decoration: none;
}

.menu li {
     /* osynlig / transparent för att ta samma utrymme som active*/
    border-bottom: 1px solid rgba(255, 255, 255, 0.0);
}
.menu>li {
    margin: 0 1em;
    list-style: none;
    margin-bottom: 10px;
    padding: 15px 0px 8px;
}
.menu .active,
.menu .current,
.menu .activeparent,
.menu li:hover
{
    color:rgb(156, 156, 156);
    text-decoration: none;
    border-bottom: 1px solid rgb(156, 156, 156);
}


/* ------------------------------------------------ DropDownMenyn / Drop Down Menu -------------------------------------------- */

 .menu .dropdown ul{
    top: calc(100% + 1px);
    left: 26px;
    background: rgba(255, 255, 255, 0.9);
    border-bottom:none;
    border-width: 0px;
}


.menu .dropdown li {
    white-space: nowrap;
    background: none;
}

.menu .dropdown li a {
    min-width: 100px;
    padding: 8px 2px 5px 2px;
    margin-left: 10px;
    margin-right: 10px;
}


/*-------------------------------------------------------------- Moduler / Modul / Länkboxar ------------------------------------------------------------------------*/

.modulbackground {
    background: #FFFFFF;
    padding-top:1vh;
    padding-bottom:1vh;
}



.moduleboxwrapper {
    position: relative;
    overflow: hidden; 
    cursor: pointer; 
    margin:0; 
    width:100%;
}


.moduleboximage {
    height: 45vh;
    position: relative;
    background-size: cover;
    background-position: center top;
    transform: scale(1.1);
    transition: transform 0.5s ease-out 0s;
}

.moduleboximage:hover {
    height: 45vh;
    position: relative;
    background-size: cover;
    background-position: center top;
    transform: scale(1.0);
    transition: transform 0.5s ease-out 0s;
}

a.moduleboximage{
    display:block;
}

.boxtitle1 {
    margin-top:32%;
    font-family:'Roboto', san-serif;
    font-weight: 500;
    font-size: 1.8em;
    letter-spacing: 1px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8);
    text-transform: uppercase;
    line-height: 1.5em;
    position:absolute; 
    height:auto;
    width:100%;
    color: #FFFFFF;
    text-align: center;
    transition: margin-top 0.3s ease-out 0s;
}

.moduleboxwrapper:hover .boxtitle1 {
    margin-top:30%;
    transition: margin-top 0.3s ease-out 0s;
}




/*---------------------------------------- Nyheter / Aktuellt / News -------------------------*/ 

.nyheter h1 {
    font-size: 3.7em;
    padding-top: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}


.nyheter {
    margin-top:15vh;
}


.nyhet {
    margin-top:15vh;
}

.blogg {
    margin-top:15vh;

}


.blogginlagg {
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;

}

.bloggtitelinramning {
   margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.blogginnehall {
    max-width:800px;
    position: relative;
    z-index: 15;
    background: rgb(255, 255, 255);
    margin-top: 0px;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    
}

.blogginnehall h1 {
    font-size: 3.7em;
    padding-top: 0px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.blogginnehall h2 {
    font-size: 1.8em;
    margin-top: 2vh;
    padding-top: 0px;
}

 

.pagination {
    margin: 0 auto;
    padding: 0;
    list-style: none;
    margin-top:21px;
    width:100%;
}

.pagination  li {
    display: inline-block;
    padding-right: 5px;
}

.pagination  li a {
    background: #999999;
    color: white;
    text-decoration: none;
    padding: 4px 8px;
}

.pagination .active a, .pagination a:hover {
    background: #666666;
}


a.lasmer  {
    font-size:0.8rem;
    border: solid;
    border-width: 0px;
    padding: 10px;

    text-decoration: none;
    background: #999999;
    color: #FFFFFF;
}

a.lasmer:hover  {
    text-decoration: none;
}



.gobackdiv {
margin-bottom:5vh;
}
    

a.goback  {
    font-size:0.8rem;
    border: solid;
    border-width: 0px;
    padding: 10px;

    text-decoration: none;
    background: #999999;
    color: #FFFFFF;
}

a.goback:hover  {
    text-decoration: none;
}


/* --------------------------------------------------------- Kontaktformulär / Kontaktbanner ----------------------------------------- */

.boltforms-row label {
    font-weight: 400;
    margin-bottom: 0;
}

.boltforms-row input {
    width: 100%;
    color: #606060;
}

.boltforms-row textarea {
    width: 100%;
    color: #606060;
    height: 10vh;
}

.boltforms-row button {
    display: block;
    width: 100%;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.2);
    margin: 0;
    border: none;
    border-radius: 2px;
    height: auto;
    padding: 10px 20px;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.1em;
}

/*--------------------- Kontaktbanner-texten ----------------------*/
.kontaktbanner {
    background: #999999;
    color: #ffffff;
    padding-top: 30px;
    padding-bottom: 10px;
}


.socialmedia {
    font-size:1.3rem;
    letter-spacing: 5px;
}

.kontaktbanner a {
    color: #ffffff;
}

.kontaktbanner h5 {
    color: #ffffff;
    font-size: 1.7em;
    margin-top: 18px;
    margin-bottom: 2vh;
}

.kontaktbanner h2 {
    padding: 0px 0 10px;
}

.kontaktbanner p {
    font-size: 1.1em;
    margin-bottom: 0px;
    line-height: 150%;
}




.kontaktbanner .boltforms-row {
    float: left;
    width: 33.3%;
    padding-right: 10px;
}

.kontaktbanner .boltforms-row:nth-child(n+4) {
    width: 100%;
}

button[type=submit], input[type=submit] {
    background: #FFFFFF;
    color: #000000;
}


button[type=submit]:hover, input[type=submit]:hover {
    background: #FFFFFF;
    color: #000000;
}

/*------------------Give map a height and it's images no max-width----------*/
    .map-canvas {
    height: 38vh;
}

.map-canvas img {
    max-width: none;
}


/*Allt under M-nivå*/
@media (max-width: 1099px) {
    
    
     
    .menu .dropdown ul {
    background: rgba(0, 35, 85, 0.0);
    }
    
    .menu.show {
    background: rgba(255,255,255,.9);
}
    
    #menu-icon {
    background: #FFFFFF;
    }
    
    h1 {
    color: #000;
    font-size: 2.3rem;
    font-weight: 300;
    letter-spacing: 2px;
}
    
    .slick-slider {
    margin-top: 80px;
    position: fixed;
    width:100%; 
    top:0;
    z-index: -10; /* z-index - sätter vilken "nivå" bildspelet / "vad man vill" ska hamna på */
}

.slick-slide {
    height: 250px;
    width:100%;
    background-size: cover;
    background-position: top center ;
}
    
    
    .topimage {
    margin-top: 80px;
    position: fixed;
    width: 100%;
    height: 250px;
    top: 0;
    z-index: -10;
    background-size: cover;
    background-position: top center;
}
    
    
    .pagecontent {
    margin-top: 325px;
    position: relative;
    z-index: 15;
    background: #fff;
}
    
    
} /* SLUT - Allt under M-nivå*/ 

/*Allt under S-nivå*/
@media (max-width: 799px) {
    
  
}  

/*Allt under XS-nivå*/
@media (max-width: 549px) {
    
    
}

@media screen and (orientation: landscape) and (max-width: 800px) {


}

@media screen and (orientation: landscape) and (max-width: 800px) {

    
}

/*--------------------------Make FB widget responsive------------------------*/
    .fb_iframe_widget,
    .fb_iframe_widget span,
    .fb_iframe_widget iframe[style],
    .twitter-timeline {
        width: 100% !important;
    }
